import React, { Fragment, useState, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import AnimatedShowMore from 'react-animated-show-more';
import dayjs from 'dayjs';

import Button from '<components>/Button';
import Link from '<components>/Link';
import {
  FlexRowContainer,
  PageContent,
} from '<components>/NumbrzPageComponents';
import { RoundedContentWrapper } from '<components>/NumbrzVerticalEditor';

import * as styles from '../../styles';
import CatalogStats from '../../CatalogStats';
import LibraryListItem from '<sections>/catalog/components/LibraryListItem';
import { modelTypeLabel } from '../CatalogListPage/CatalogItem';
import { CategoryBtn } from '<sections>/catalog/components/styles';

function ShowMore({ isOpen }) {
  return <Button.Small>{isOpen ? 'Show Less' : 'ShowMore'}</Button.Small>;
}

function GetBar({ catalogProject, deploying, onDeploy, onUpgrade }) {
  if (!catalogProject.currentReleaseID) {
    return null;
  }
  const deplProj = catalogProject.deployments[0];
  if (!deplProj) {
    return (
      <styles.GetButtonBar>
        <Button
          action="deploy"
          title="Get"
          onClick={() => onDeploy()}
          disabled={deploying}
        >
          {catalogProject.isLibrary ? 'Get all' : 'Get'}
        </Button>
        {catalogProject.templateURL ? (
          <Link.ExternalResults
            href={catalogProject.templateURL}
            label="Companion Spreadsheet"
            title="Open Companion Spreadsheet"
          />
        ) : null}
      </styles.GetButtonBar>
    );
  } else if (deplProj.upgradeAvailable) {
    return (
      <styles.GetButtonBar>
        <Button
          action="deploy"
          title={deploying ? 'Upgrading...' : 'Upgrade'}
          onClick={() => onUpgrade(deplProj.ID)}
          disabled={deploying}
        >
          Upgrade
        </Button>
      </styles.GetButtonBar>
    );
  } else {
    return (
      <styles.GetButtonBar>
        <Link button to={`/models/${deplProj.ID}`}>
          Open
        </Link>
      </styles.GetButtonBar>
    );
  }
}

const Entities = [
  {
    label: 'Functions',
    value: 'FunctionV3',
  },
  {
    label: 'Flows',
    value: 'Flow',
  },
];

export default function CatalogProject({
  catalogProject,
  now,
  deploying,
  onDeploy,
  onUpgrade,
  libFunctions = [],
  libFlows = [],
  onViewFx,
  onViewFlow,
}) {
  const currRelease = catalogProject.releases.find(
    (r) => r.ID === catalogProject.currentReleaseID
  );

  const [selectedCategory, setSelectedCategory] = useState('FunctionV3');
  const items = useMemo(() => {
    const allItems = [...libFunctions, ...libFlows];
    return allItems.filter((item) => item.__typename === selectedCategory);
  }, [libFlows, libFunctions, selectedCategory]);

  return (
    <PageContent>
      <RoundedContentWrapper>
        <styles.Header>
          <styles.OrgIcon />
          <div style={{ width: '100%' }}>
            <styles.Title>
              <h2>
                {catalogProject.name}
                <FlexRowContainer>
                  {!catalogProject.currentReleaseID && (
                    <styles.CategoryChiclet>Coming Soon</styles.CategoryChiclet>
                  )}
                  {catalogProject.helpWanted && (
                    <styles.CategoryChiclet>Help Wanted</styles.CategoryChiclet>
                  )}
                </FlexRowContainer>
              </h2>
              <h4 style={{ marginBottom: '10px' }}>
                {catalogProject.isLibrary
                  ? 'Library'
                  : modelTypeLabel[catalogProject.type]}
              </h4>
              <h4>{catalogProject.tagLine}</h4>
            </styles.Title>
            <GetBar
              catalogProject={catalogProject}
              deploying={deploying}
              onDeploy={onDeploy}
              onUpgrade={onUpgrade}
            />
          </div>
        </styles.Header>
        {catalogProject.stats ? (
          <styles.Section>
            <CatalogStats catalogProject={catalogProject} />
          </styles.Section>
        ) : null}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            padding: '20px 0 0 5px',
          }}
        >
          {Entities.map((entity) => (
            <CategoryBtn
              selected={selectedCategory === entity.value}
              onClick={() => setSelectedCategory(entity.value)}
            >
              {entity.label}
            </CategoryBtn>
          ))}
        </div>
        {items.length > 0 && (
          <styles.Section>
            <styles.LibraryCardList>
              {items.map((item, idx) => (
                <LibraryListItem
                  key={idx}
                  item={item}
                  onGetFunx={() => onViewFx(item.ID)}
                  onGetFlow={() => onViewFlow(item.ID)}
                />
              ))}
            </styles.LibraryCardList>
          </styles.Section>
        )}

        {catalogProject.description && (
          <styles.Section>
            <AnimatedShowMore height={250} speed={100} toggle={ShowMore}>
              <ReactMarkdown>{catalogProject.description}</ReactMarkdown>
            </AnimatedShowMore>
          </styles.Section>
        )}

        {currRelease && catalogProject.releases.length > 1 ? (
          <styles.WhatsNew>
            <div>
              <h3>What's New</h3>
              <AnimatedShowMore height={80} speed={100} toggle={ShowMore}>
                <ReactMarkdown>{currRelease.releaseNotes}</ReactMarkdown>
              </AnimatedShowMore>
            </div>
            <styles.Versions>
              {/* <h4>
                <Link to="versions">Version history</Link>
              </h4> */}
              <div>{dayjs(now).to(currRelease.releasedAt)}</div>
              <div>version {currRelease.releaseTag}</div>
            </styles.Versions>
          </styles.WhatsNew>
        ) : null}
        <styles.ModelInfo>
          {catalogProject.helpWanted && (
            <>
              <h3>Help Wanted</h3>
              <AnimatedShowMore height={80} speed={100} toggle={ShowMore}>
                <ReactMarkdown>{catalogProject.helpInfo}</ReactMarkdown>
              </AnimatedShowMore>
            </>
          )}
          <h3>Information</h3>
          <div>
            <div style={{ gridRow: 1, gridColumn: 1 }}>
              <h4>Builder</h4>
              {catalogProject.owner.display}
            </div>
            {catalogProject.category ? (
              <div style={{ gridRow: 1, gridColumn: 2 }}>
                <h4>Category</h4>
                {catalogProject.category}
              </div>
            ) : null}
            {catalogProject.tags.length > 0 ? (
              <div
                style={{
                  gridRow: catalogProject.category ? 2 : 1,
                  gridColumn: 2,
                }}
              >
                <h4>Tags</h4>
                <ul>
                  {catalogProject.tags.map((tag, idx) => (
                    <li key={idx}>{tag}</li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </styles.ModelInfo>
      </RoundedContentWrapper>
    </PageContent>
  );
}
