import React, { useMemo, useState, useCallback, useEffect } from 'react';
import fp from 'lodash/fp';
import ActionMenu from '<components>/ActionMenu';
import Card from '<components>/Card';
import Button from '<src>/components/Button';
import Link from '<components>/Link';
import { Icon } from 'semantic-ui-react';

import {
  CardListItem,
  FlexRowContainer,
  ErrorChiclet,
  CardSubhdr,
} from '<src>/components/NumbrzPageComponents';
import { NumbrzTooltip } from '<components>/NumbrzButtons';
import { formatDataSourceLabel } from '<sections>/data/utils';

import { PrimaryLabel } from './styles';

const resources = [
  {
    type: 'FunctionV3',
    label: 'function',
  },
  {
    type: 'Job',
    label: 'job',
  },
  {
    type: 'JobGroup',
    label: 'job group',
  },
  {
    type: 'Flow',
    label: 'flow',
  },
];

export const getResourceLabel = (type) => {
  const r = resources.find((r) => r.type === type);
  return r ? r.label : 'resource';
};

export default function CardItem({
  entityType,
  item,
  baseURL,
  handleRunItem: onRun,
  handleDeleteItem,
  handleCloneItem,
  excludeDelete,
  excludeClone,
  history,
  itemsRunning = [],
  setRunResult,
}) {
  const [itemRunning, setItemRunning] = useState(false);

  useEffect(() => {
    if (entityType === 'job-group') {
      if (!itemRunning && itemsRunning.find((i) => i.groupID === item.ID))
        setItemRunning(true);
      if (itemRunning && !itemsRunning.find((i) => i.groupID === item.ID))
        setItemRunning(false);
    }
  }, [itemsRunning, item.ID, entityType, itemRunning]);

  const handleRunItem = useCallback(async () => {
    setItemRunning(true);
    let res;
    let payload;

    if (entityType === 'job' || entityType === 'model') {
      const jobID = entityType === 'job' ? item.ID : item.primaryJobBinding.ID;
      const projectID = entityType === 'job' ? item.projectID : item.ID;

      payload = {
        variables: { input: { jobID } },
      };
      res = await onRun(payload);
      setRunResult(res);
      if (fp.getOr(undefined, 'data.runJob.success', res)) {
        const url = `/models/${projectID}/jobs/${jobID}/status/${res.data.runJob.runID}`;

        history.push(url);
      }
    }
    if (entityType === 'job-group') {
      res = await onRun({ variables: { jobGroupID: item.ID } });
      setRunResult(res);
      /*

      handle the case when a group is referencing a job that is already running
      show a pop up indicating that this job can't be run until the other finishes.
      */

      const runStateID = fp.getOr(undefined, 'data.startJobGroup', res);
      if (runStateID)
        history.push(`${baseURL}/${item.ID}/status/${runStateID}`);
    }

    setItemRunning(false);
  }, [
    entityType,
    item.ID,
    item.projectID,
    history,
    onRun,
    item.primaryJobBinding,
    baseURL,
    setRunResult,
  ]);

  const externalResultURL =
    item?.primaryJobBinding?.externalResultURL ||
    item.sourceURL ||
    item.externalResultURL ||
    item?.source?.url;

  const externalResultLink = useMemo(
    () =>
      externalResultURL ? (
        <Link.ExternalResults
          href={externalResultURL}
          size="small"
          label={['data', 'data-table'].includes(entityType) && 'Source'}
        />
      ) : null,
    [externalResultURL, entityType]
  );

  const errorTooltip = useMemo(() => {
    switch (entityType) {
      case 'data':
        return (
          item.needsAuth && (
            <NumbrzTooltip
              content="This tableset requires authentication."
              trigger={
                <ErrorChiclet visible={item.needsAuth ? 'true' : 'false'}>
                  <Icon name="circle" />
                  <Icon
                    name="exclamation"
                    title={'This tableset requires authentication.'}
                  />
                </ErrorChiclet>
              }
            />
          )
        );
      case 'job':
      case 'flow':
      case 'job-group':
        return (
          !item.runnable && (
            <NumbrzTooltip
              content={`This ${getResourceLabel(item.__typename)} contains errors`}
              trigger={
                <ErrorChiclet visible={!item.runnable ? 'true' : 'false'}>
                  <Icon name="circle" />
                  <Icon
                    name="exclamation"
                    title={`This ${getResourceLabel(item.__typename)} contains errors`}
                  />
                </ErrorChiclet>
              }
            />
          )
        );

      default:
        return false;
    }
  }, [entityType, item.needsAuth, item.__typename, item.runnable]);

  const title = () => {
    switch (item.__typename) {
      case 'FunctionV3':
      case 'Flow':
      case 'Tableset':
      case 'Project':
      case 'DataTable':
      case 'JobGroup':
        return item.name;
      case 'Job':
        return (
          <span>
            {item.name} {item.primary && <PrimaryLabel>(Primary)</PrimaryLabel>}
          </span>
        );
      default:
        return `Unknown ${item.__typename}`;
    }
  };

  const header = () => {
    return (
      <Card.Header>
        <FlexRowContainer
          alignItems="baseline"
          justifyContent="space-between"
          width="100%"
        >
          <Link
            internal
            to={`${baseURL}/${item.ID}`}
            style={{ paddingLeft: 0 }}
          >
            {title()}
          </Link>
          <ActionMenu
            options={[
              ActionMenu.cloneOption({
                exclude: excludeClone,
                onSelect: (e) => handleCloneItem(e, item),
              }),
              ActionMenu.deleteOption({
                onSelect: (e) => handleDeleteItem(e, item),
                exclude: excludeDelete,
              }),
            ]}
          />
        </FlexRowContainer>
        {errorTooltip}
        {entityType === 'data' && (
          <CardSubhdr>
            {formatDataSourceLabel(
              item.source?.__typename,
              item.connector?.name
            )}
          </CardSubhdr>
        )}
      </Card.Header>
    );
  };

  const footer = () => {
    const runnable = item.runnable || item?.primaryJobBinding?.runnable;
    const hideRunBtn = !onRun
      ? true
      : !['job', 'model', 'job-group'].includes(entityType)
        ? true
        : !runnable;

    return (
      <Card.Footer justifyContent="space-between">
        {!hideRunBtn && (
          <Button.Run
            size="small"
            type="button"
            title={`Run ${getResourceLabel(item.__typename)}`}
            onClick={handleRunItem}
            disabled={!runnable || itemRunning}
          >
            {itemRunning ? 'Running...' : 'Run'}
          </Button.Run>
        )}
        {externalResultLink}
      </Card.Footer>
    );
  };

  return (
    <CardListItem>
      {header()}
      {
        // body()
      }
      {footer()}
    </CardListItem>
  );
}
