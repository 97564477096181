/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Card from '<components>/Card';

import NBZIcon from '<src>/assets/numbrz_icon.png';
import NBZIconCircle from '<src>/assets/numbrz_icon_circle.png';
import { RoundChiclet } from '<components>/NumbrzButtons';

import * as colors from '<components>/colors';
import {
  FlexColumnContainer,
  FlexRowContainer,
} from '<components>/NumbrzPageComponents';
import { CardContainer } from '<sections>/jobs/styles';

export const CategoryChiclet = styled(RoundChiclet)`
  border: 1px solid black;
  color: black;
  font-size: 11px;
  margin-top: 10px;
`;

export const Title = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
  h2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    span {
      margin-top: 0;
      margin-right: 10px;
    }
  }
  h2,
  h4 {
    margin: 0;
  }
  h4 {
    color: ${colors.gray2};
    font-weight: normal;
  }
`;

export const Section = styled('div')`
  border-bottom: 1px solid ${colors.gray_border};
  padding: 18px 0;
  :last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  :first-child {
    padding-top: 0;
  }
`;

export const Header = styled(Section)`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 110px;
  }
`;

export const Stats = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  padding: 6px 0;
`;

const sf = 0.85;

export const Stat = styled('div')`
  min-height: ${({ small }) => (small ? sf : 1) * 60}px;
  padding: 0 ${({ small }) => (small ? sf : 1) * 24}px;
  display: flex;
  flex-direction: row;
  align-items: center;

  :not(:last-child):after {
    content: '';
    background: ${colors.gray_border};
    width: 1px;
    height: ${({ small }) => (small ? sf : 1) * 45}px;
    position: relative;
    right: ${({ small }) => (small ? sf : 1) * -24}px;
    display: inline-block;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
  }

  .title {
    color: ${colors.gray2};
    text-transform: uppercase;
    font-size: ${({ small }) => (small ? sf : 1) * 0.8}em;
    margin: 4px 0;
  }
  .footer {
    color: ${colors.gray2};
    font-size: ${({ small }) => (small ? sf : 1) * 0.8}em;
    margin: 4px 0;
  }
  .value {
    color: ${colors.gray1};
    font-weight: bold;
    font-size: ${({ small }) => (small ? sf : 1) * 1.5}em;
  }
`;

export const WhatsNew = styled(Section)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & :first-child {
    flex: 1;
  }
`;

export const Versions = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 180px;
`;

export const ModelInfo = styled(Section)`
  h4 {
    color: ${colors.gray2};
    font-weight: normal;
    font-size: 0.85em;
    margin-bottom: 4px !important;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  & > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
`;

export function OrgIcon() {
  return (
    <img
      src={NBZIcon}
      alt="logo"
      css={css`
        width: 100px;
        height: 80px;
        margin-right: 10px;
      `}
    />
  );
}

export function OrgIconCircle() {
  return (
    <img
      src={NBZIconCircle}
      alt="logo"
      css={css`
        width: 30px;
        height: 30px;
      `}
    />
  );
}

export const GetButtonBar = styled('div')`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 12px;
`;

export const ListItem = styled(Link)`
  display: flex;
  flex-direction: column;
  color: ${colors.black};
  :hover {
    color: ${colors.black};
  }
  background: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.gray3};
  color: ${colors.black};
  padding: 16px 24px;
  width: 348px;
`;

ListItem.Header = styled('header')`
  padding-bottom: 16px;
  h2,
  h3 {
    margin: 0;
  }
  h2 {
    font-size: 1.6em;
  }
  a:hover & h2 {
    color: ${colors.blue1};
  }
  h3 {
    margin-top: 4px;
    font-size: 1.1em;
    font-weight: 500;
    color: ${colors.gray2};
  }
`;

ListItem.Body = styled('div')`
  flex: 1;
  & div.tag-line {
    line-height: 2.8ex;
    max-height: 8.4ex;
    overflow: hidden;
  }
`;

ListItem.Footer = styled('footer')`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > :first-child {
    flex: 1;
  }
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid ${colors.gray3};
  a {
    margin-left: 8px;
  }
`;

export const LibraryList = styled(FlexColumnContainer)`
  width: 100%;
  padding: 2px 4px;
  border-radius: 4px;
  border: 1px solid ${colors.gray_border};
`;

export const LibraryListItem = styled(FlexColumnContainer)`
  justify-content: space-between;
  align-items: baseline;
  font-size: 12px;
  width: 100%;
  padding: 0;
  margin: 5px 0;
  border-radius: 5px;
  color: ${colors.gray1b};
  button {
    margin-left: 5px;
    &.active {
      font-weight: bold;
    }
  }

  svg {
    margin: auto 0;
    font-size: 40px;
    color: ${colors.blue1};
  }
  svg.arrow {
    font-size: 25px;
    color: ${colors.gray2};
  }

  .fx-name {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.5px;
    margin-right: 10px;
  }
  .funx-desc {
    margin: 5px 0 10px 0;
  }

  .input-output-lbl {
    margin: 0 5px;
    font-weight: bold;
  }

  .data-type {
    margin: 0 5px;
    color: ${colors.gray2};
  }
`;

export const LibraryCardList = styled(FlexRowContainer)`
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const LibraryCardListItem = styled(Card)`
  width: unset;
  height: unset;
  min-width: 100px;
  min-height: 90px;
  max-width: 200px;
  margin-right: 20px;
  margin-bottom: 20px;

  header {
    padding: 10px;
  }
`;

export const FunxCardContainer = styled(CardContainer)`
  min-width: unset;
  padding: 0;
`;

export const FunxCardHdrBtn = styled(FlexRowContainer)`
  margin: 5px;
  padding: 5px 2px 5px 7px;
  justify-content: flex-start;
  align-items: center;
  letter-spacing: 0.4px;
  :hover {
    cursor: pointer;
    background-color: ${colors.gray7};
    border-radius: 4px;
  }
`;

export const ImportLibraryForm = styled(FlexColumnContainer)`
  align-items: flex-start;
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  padding: 10px;
  .checkbox {
    font-size: 14px;
  }
`;
