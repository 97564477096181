import React from 'react';
import { LibraryListItem } from '<sections>/catalog/styles';
import { FlexRowContainer } from '<components>/NumbrzPageComponents';
import EditableMarkdown from '<components>/EditableMarkdown';

export default function FlowListItem({ flow }) {
  return (
    <LibraryListItem expanded={true}>
      <FlexRowContainer
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <FlexRowContainer
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
        ></FlexRowContainer>
      </FlexRowContainer>

      <EditableMarkdown content={flow.comment} editable={false} />
    </LibraryListItem>
  );
}
