import React, { useCallback } from 'react';
import { useMutation } from '<src>/apollo/client';

import UserVariablesEditor from '<sections>/flows/UserVariablesEditor';

import { UpdateJob } from '../queries';

export default function JobVariables({
  jobID,
  flowID,
  flowName,
  jobVariables = [],
  flowVariables = [],
}) {
  const [updateJob] = useMutation(UpdateJob);

  const onAddUpdateUserVar = useCallback(
    (e, userVar) => {
      const newVars = jobVariables.map((jV) => {
        const flowVar = flowVariables.find((fV) => fV.toElement.key === jV.key);
        return { ...jV, explicitType: flowVar?.toElement?.type };
      });

      const jobVarIdx = jobVariables.findIndex(
        (jV) => jV.key === userVar.toElement.key
      );

      if (jobVarIdx >= 0) {
        newVars[jobVarIdx] = { ...newVars[jobVarIdx] };
        newVars[jobVarIdx].val = userVar.defaultVal;
        newVars[jobVarIdx].explicitType = userVar.toElement.type;
      } else {
        newVars.push({
          key: userVar.toElement.key,
          val: userVar.defaultVal,
          explicitType: userVar.toElement.type,
        });
      }

      updateJob({
        variables: {
          input: { ID: jobID, variables: newVars },
        },
      });
    },
    [jobVariables, jobID, updateJob]
  );

  return (
    <UserVariablesEditor
      flowID={flowID}
      userVariables={flowVariables}
      jobVariables={jobVariables}
      onAddUpdateUserVar={onAddUpdateUserVar}
      onRemoveUserVar={() => {}}
      mode="job"
    />
  );
}
