import React from 'react';
import Card from '<components>/Card';
import Button from '<components>/Button';
import * as styles from '../../styles';

export default function LibraryListItem({ item, onGetFunx, onGetFlow }) {
  const callback = item.__typename === 'FunctionV3' ? onGetFunx : onGetFlow;
  return (
    <styles.LibraryCardListItem>
      <Card.Header>
        <Card.Headline>{item.name}</Card.Headline>
      </Card.Header>
      <Card.Body />
      <Card.Footer>
        <Button.Run
          size="small"
          onClick={callback}
          style={{ marginLeft: '10px' }}
        >
          Get
        </Button.Run>
      </Card.Footer>
    </styles.LibraryCardListItem>
  );
}
