import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useMutation, useQuery } from '<src>/apollo/client';

import Dialog from '<components>/Dialog';
import Button from '<src>/components/Button';
import { FormCheckbox } from 'semantic-ui-react';

import { DeployLibraryContent, GetProjects } from '<sections>/projects/queries';

import WaveSpinner from '<components>/WaveSpinner';
import FunctionListItem from './FunctionListItem';
import FlowListItem from './FlowListItem';
import { TargetPicker } from '<sections>/projects/pages/ProjectPage/components/CloneFunctionDialog';
import { FlexColumnContainer } from '<components>/NumbrzPageComponents';
import { ImportLibraryForm } from '<sections>/catalog/styles';
import ErrorMessages from '<components>/ErrorMessages';

function DeployAllControls({
  deployAllFunctions,
  deployAllFlows,
  setDeployAllFlows,
  setDeployAllFunctions,
}) {
  return (
    <ImportLibraryForm>
      <FormCheckbox
        checked={deployAllFunctions}
        label="All Functions"
        onChange={(e, { checked }) => setDeployAllFunctions(checked)}
      />
      <FormCheckbox
        checked={deployAllFlows}
        label="All Flows"
        onChange={(e, { checked }) => setDeployAllFlows(checked)}
      />
    </ImportLibraryForm>
  );
}

export default function LibraryDialog({
  visible,
  onClose,
  catalogProjectID,
  libraryName = 'Library',
  libFunctions,
  libFlows,
  activeFxID,
  activeFlowID,
  loading,
}) {
  const [deployContent] = useMutation(DeployLibraryContent);
  const [deploying, setDeploying] = useState(false);
  const [deployAllFunctions, setDeployAllFunctions] = useState(true);
  const [deployAllFlows, setDeployAllFlows] = useState(true);
  const [errors, setErrors] = useState([]);

  const [targetModelID, setTargetModelID] = useState('add_new');

  const { data: { projects = [] } = {}, loading: loadingProjects } =
    useQuery(GetProjects);

  // functions can only be deployed to project of type Standard
  const standardProjects = useMemo(
    () => [
      {
        name: libraryName,
        ID: 'add_new',
      },
      ...projects.filter(
        (p) => p.type === 'Standard' && p.ID !== catalogProjectID
      ),
    ],
    [projects, catalogProjectID, libraryName]
  );

  useEffect(() => {
    if (targetModelID === 'add_new' && standardProjects.length > 0) {
      const existingFunxLib = standardProjects.find(
        (p) => p.name === libraryName && p.ID !== 'add_new'
      );

      if (existingFunxLib) setTargetModelID(existingFunxLib.ID);
    }
  }, [targetModelID, standardProjects, libraryName]);

  const activeFx = useMemo(
    () => libFunctions.find((f) => f.ID === activeFxID),
    [activeFxID, libFunctions]
  );

  const activeFlow = useMemo(
    () => libFlows.find((f) => f.ID === activeFlowID),
    [activeFlowID, libFlows]
  );

  const deployAll = useMemo(() => {
    return !activeFx && !activeFlow;
  }, [activeFlow, activeFx]);

  const handleOnClose = useCallback(() => {
    setTargetModelID('add_new');
    onClose();
  }, [onClose]);

  const deployItems = useCallback(
    async (item) => {
      let payload = {
        projectID: catalogProjectID,
        targetID: targetModelID === 'add_new' ? null : targetModelID,
        ...(targetModelID === 'add_new' && { addTargetModel: true }),
        ...(targetModelID === 'add_new' && { targetModelName: libraryName }),
      };
      setErrors([]);
      setDeploying(true);
      let res;

      if (deployAll) {
        if (deployAllFunctions)
          payload.functions = libFunctions.map((fx) => fx.ID);
        if (deployAllFlows) payload.flows = libFlows.map((f) => f.ID);

        res = await deployContent({ variables: { input: payload } });
      } else if (item) {
        if (item.__typename === 'FunctionV3') {
          payload.functions = [item.ID];
          payload.flows = [];
        } else {
          payload.flows = [item.ID];
          payload.functions = [];
        }

        res = await deployContent({ variables: { input: payload } });
      }
      setDeploying(false);
      const { errors } = res;
      if (errors.length > 0) setErrors(errors);
      if (errors.length === 0) {
        handleOnClose();
      }
    },
    [
      deployAll,
      deployAllFlows,
      deployAllFunctions,
      catalogProjectID,
      deployContent,
      libFunctions,
      libFlows,
      targetModelID,
      libraryName,
      handleOnClose,
    ]
  );

  const itemHdr = activeFx
    ? activeFx.name
    : activeFlow
      ? activeFlow.name
      : libraryName;

  const activeItem = activeFx || activeFlow;

  return (
    <Dialog
      visible={visible}
      onRequestClose={handleOnClose}
      style={{ maxWidth: 'initial', minWidth: '50%' }}
    >
      <Dialog.Header>
        <Dialog.Headline>{itemHdr}</Dialog.Headline>
        <Button.DialogClose onClick={handleOnClose} />
      </Dialog.Header>
      <Dialog.Body>
        {loading || loadingProjects ? (
          <WaveSpinner />
        ) : libFunctions.length > 0 || libFlows.length > 0 ? (
          <FlexColumnContainer width="100%">
            {errors.length > 0 && (
              <ErrorMessages issues={errors} width="100%" />
            )}
            <TargetPicker
              projects={standardProjects}
              targetProjectID={targetModelID}
              setTargetProjectID={setTargetModelID}
            />
            {deployAll && (
              <DeployAllControls
                deployAllFlows={deployAllFlows}
                deployAllFunctions={deployAllFunctions}
                setDeployAllFlows={setDeployAllFlows}
                setDeployAllFunctions={setDeployAllFunctions}
              />
            )}
            {activeFx && <FunctionListItem fx={activeFx} />}
            {activeFlow && <FlowListItem flow={activeFlow} />}
          </FlexColumnContainer>
        ) : (
          'No items found'
        )}
      </Dialog.Body>
      <Dialog.Footer>
        {activeFx || activeFlow ? (
          <Button.Run
            onClick={() => deployItems(activeItem)}
            disabled={deploying}
            title={'Import'}
          >
            {deploying ? 'Importing...' : 'Import'}
          </Button.Run>
        ) : (
          <Button.Run onClick={() => deployItems(null)} disabled={deploying}>
            {deploying ? 'Importing All...' : 'Import All'}
          </Button.Run>
        )}
      </Dialog.Footer>
    </Dialog>
  );
}
