import gql from 'graphql-tag';

export const DataTableItemFragment = gql`
  fragment DataTableItemFragment on DataTable {
    ID
    name
    description
    sourceURL
    __typename
    projectIDs {
      ID
      name
    }
    state {
      ready
      issues {
        code
        desc
      }
    }
  }
`;

export const UpdateDataTableName = gql`
  mutation UpdateDataTableName($input: UpdateDataTableNameInput!) {
    updateDataTableName(input: $input) {
      success
    }
  }
`;

export const DeleteDataTable = gql`
  mutation DeleteDataTable($ID: CommonID!, $TableSource: String!) {
    deleteDataTable(ID: $ID, TableSource: $TableSource)
  }
`;

export const TablesetFragment = gql`
  fragment TablesetFragment on Tableset {
    ID
    credentialID
    name
    comment
    needsAuth
    state {
      ready
      issues {
        code
        desc
      }
    }
    deployKey
    __typename
    connector {
      name
      params {
        name
        val
      }
    }
    source {
      ... on GoogleSpreadsheet {
        url
      }
      ... on ExcelSpreadsheet {
        shareURL
      }
      ... on DropboxFolder {
        path
      }
      ... on NumbrzDatabase {
        type
      }
      ... on QuickbooksOnline {
        type
      }
    }
  }
`;

export const GetTablesets = gql`
  query GetTablesets($ownerID: CommonID) {
    tablesets(input: { ownerID: $ownerID }) {
      tablesets {
        ...TablesetFragment
      }
    }
  }

  ${TablesetFragment}
`;

export const GetGsheetAccessAgent = gql`
  query GetGsheetAccessAgent {
    gsheetAccessAgent {
      key
      isDefault
    }
  }
`;

export const CreateTableset = gql`
  mutation CreateTableset($input: CreateTablesetInput!) {
    createTableset(input: $input) {
      ...TablesetFragment
    }
  }
  ${TablesetFragment}
`;

export const LinkGoogleSpreadsheet = gql`
  mutation LinkGoogleSpreadsheet($input: LinkGoogleSpreadsheetInput!) {
    linkGoogleSpreadsheet(input: $input) {
      ...TablesetFragment
    }
  }

  ${TablesetFragment}
`;

export const LinkBigQuery = gql`
  mutation LinkBigQuery($input: LinkBigQueryInput!) {
    linkBigQuery(input: $input) {
      ...TablesetFragment
    }
  }

  ${TablesetFragment}
`;

export const LinkMotherDuckDB = gql`
  mutation LinkMotherDuckDB($input: LinkMotherDuckInput!) {
    linkMotherDuckDB(input: $input) {
      ...TablesetFragment
    }
  }

  ${TablesetFragment}
`;

export const LinkDropboxFile = gql`
  mutation LinkDropboxFile($input: LinkDropboxFileInput!) {
    linkDropboxFile(input: $input) {
      ...TablesetFragment
    }
  }

  ${TablesetFragment}
`;

export const LinkExcelSpreadsheet = gql`
  mutation LinkExcelSpreadsheet($input: LinkExcelSpreadsheetInput!) {
    linkExcelSpreadsheet(input: $input) {
      ...TablesetFragment
    }
  }

  ${TablesetFragment}
`;

export const LinkQBSource = gql`
  mutation LinkQBSource($input: LinkQBSourceInput!) {
    linkQBSource(input: $input) {
      ...TablesetFragment
    }
  }

  ${TablesetFragment}
`;

export const LinkSnowflakeDatabase = gql`
  mutation LinkSnowflakeDatabase($input: LinkSnowflakeDatabaseInput!) {
    linkSnowflakeDatabase(input: $input) {
      ...TablesetFragment
    }
  }

  ${TablesetFragment}
`;

export const DeleteTableset = gql`
  mutation DeleteTableset($ID: CommonID!) {
    deleteTableset(ID: $ID)
  }
`;

export const GetTableset = gql`
  query GetTableset($ID: CommonID!) {
    tableset(ID: $ID) {
      ...TablesetFragment
    }
  }

  ${TablesetFragment}
`;

export const UpdateTableset = gql`
  mutation UpdateTableset($input: UpdateTablesetInput!) {
    updateTableset(input: $input) {
      ...TablesetFragment
    }
  }

  ${TablesetFragment}
`;

export const AuthenticateOAuthConnector = gql`
  mutation AuthenticateOAuthConnector(
    $input: AuthenticateOauthConnectorInput!
  ) {
    authenticateOAuthConnector(input: $input) {
      ...TablesetFragment
    }
  }
  ${TablesetFragment}
`;

export const AuthenticatePasswordConnector = gql`
  mutation AuthenticatePasswordConnector(
    $input: AuthenticatePasswordConnectorInput!
  ) {
    authenticatePasswordConnector(input: $input) {
      ...TablesetFragment
    }
  }
  ${TablesetFragment}
`;

export const DataTableFragment = gql`
  fragment DataTableFragment on DataTable {
    ID
    name
    description
    sourceURL
    deployKey
    __typename
    features {
      where
      sort
      aggregate
      select
      insert
      update
      delete
      truncate
      link
      create
      alter
      drop
    }
    columns {
      ID
      externalID
      label
      description
      type
    }
    state {
      ready
      issues {
        code
        desc
      }
    }
    link {
      params {
        name
        val
      }
    }

    source {
      ... on GoogleSheet {
        sheetID
      }
      ... on ExcelSheet {
        worksheetID
      }
      ... on SnowflakeTable {
        tableName
      }
      ... on NumbrzTable {
        sessionData
      }
      ... on QuickbooksSource {
        entityID
        dateMacro
        fromTo {
          fromDate
          toDate
        }
      }
    }
  }
`;

export const GetTablesetTables = gql`
  query GetTablesetTables($ID: CommonID!) {
    tableset(ID: $ID) {
      ID
      __typename
      tables {
        ...DataTableFragment
      }
    }
  }

  ${DataTableFragment}
`;

export const ParamMetaFragment = gql`
  fragment ParamMetaFragment on ParamMeta {
    name
    dataType
    groupKey
    options {
      val
      displayString
    }
    required
  }
`;

export const GetTablesetExternalTables = gql`
  query GetTablesetExternalTables($ID: CommonID!) {
    tableset(ID: $ID) {
      ID
      externalTables {
        tables {
          ID
          name
          columns {
            externalID
            label
            description
            type
          }
          linkParams {
            ...ParamMetaFragment
          }
          paramValues {
            name
            val
          }
          source {
            ... on GoogleSheet {
              sheetID
            }
            ... on ExcelSheet {
              worksheetID
            }
            ... on SnowflakeTable {
              tableName
            }
            ... on FileSource {
              name
              format
            }
            ... on NumbrzTable {
              sessionData
            }
            ... on QuickbooksSource {
              entityID
              dateMacro
              fromTo {
                fromDate
                toDate
              }
            }
          }
        }
        err
      }
      tables {
        ID
        name
        source {
          ... on GoogleSheet {
            sheetID
          }
          ... on ExcelSheet {
            worksheetID
          }
          ... on SnowflakeTable {
            tableName
          }
          ... on FileSource {
            name
            format
          }
          ... on NumbrzTable {
            sessionData
          }
          ... on QuickbooksSource {
            entityID
          }
        }
        link {
          tableID
          params {
            name
            val
          }
          readOnly
        }
      }
    }
  }
  ${ParamMetaFragment}
`;

export const LinkQBTableToTableset = gql`
  mutation LinkQBTableToTableset(
    $tablesetID: CommonID!
    $entityID: QBEntityID!
    $fromTo: FromToRangeInput
    $dateMacro: QBDateMacro
    $name: String
  ) {
    linkQBTableToTableset(
      input: {
        tablesetID: $tablesetID
        entityID: $entityID
        fromTo: $fromTo
        dateMacro: $dateMacro
        name: $name
      }
    ) {
      ...DataTableFragment
    }
  }

  ${DataTableFragment}
`;

export const UpdateTableLinkParams = gql`
  mutation UpdateTableLinkParams($input: UpdateTableLinkParamsInput!) {
    updateLinkParams(input: $input) {
      ...DataTableFragment
    }
  }
  ${DataTableFragment}
`;

export const LinkTableToTableset = gql`
  mutation LinkTableToTableset(
    $tablesetID: CommonID!
    $name: String
    $link: LinkMetaInput!
  ) {
    linkTableToTableset(
      input: { tablesetID: $tablesetID, name: $name, link: $link }
    ) {
      ...DataTableFragment
    }
  }
  ${DataTableFragment}
`;

export const LinkGSheetToTableset = gql`
  mutation LinkGSheetToTableset($tablesetID: CommonID!, $sheetID: ID!) {
    linkGSheetToTableset(
      input: { tablesetID: $tablesetID, sheetID: $sheetID }
    ) {
      ...DataTableFragment
    }
  }

  ${DataTableFragment}
`;

export const LinkExcelSheetToTableset = gql`
  mutation LinkExcelSheetToTableset($tablesetID: CommonID!, $worksheetID: ID!) {
    linkExcelSheetToTableset(
      input: { tablesetID: $tablesetID, worksheetID: $worksheetID }
    ) {
      ...DataTableFragment
    }
  }

  ${DataTableFragment}
`;

export const LinkSnowflakeTableToTableset = gql`
  mutation LinkSnowflakeTableToTableset(
    $tablesetID: CommonID!
    $tableName: String!
  ) {
    linkSnowflakeTableToTableset(
      input: { tablesetID: $tablesetID, tableName: $tableName }
    ) {
      ...DataTableFragment
    }
  }

  ${DataTableFragment}
`;

export const LinkFileToTableset = gql`
  mutation LinkFileToTableset(
    $tablesetID: CommonID!
    $name: String!
    $format: String!
  ) {
    linkFileToTableset(
      input: { tablesetID: $tablesetID, name: $name, format: $format }
    ) {
      ...DataTableFragment
    }
  }

  ${DataTableFragment}
`;

export const UpdateTable = gql`
  mutation UpdateTable($input: UpdateDataTableInput!) {
    updateDataTable(input: $input) {
      ...DataTableFragment
    }
  }

  ${DataTableFragment}
`;

export const UpdateTableColumn = gql`
  mutation UpdateTableColumn($input: UpdateDataTableColumnInput!) {
    updateDataTableColumn(input: $input) {
      ...DataTableFragment
    }
  }

  ${DataTableFragment}
`;

export const AddTableColumn = gql`
  mutation AddDataTableColumn(
    $tableID: CommonID!
    $type: DataColumnType!
    $label: String!
    $source: String!
  ) {
    addDataTableColumn(
      input: { tableID: $tableID, label: $label, type: $type, source: $source }
    ) {
      ...DataTableFragment
    }
  }

  ${DataTableFragment}
`;

export const DeleteTableColumn = gql`
  mutation DeleteDataTableColumn(
    $tableID: CommonID!
    $columnID: ID!
    $source: String!
  ) {
    deleteDataTableColumn(
      input: { tableID: $tableID, columnID: $columnID, source: $source }
    ) {
      ...DataTableFragment
    }
  }

  ${DataTableFragment}
`;

export const UpdateTableColumns = gql`
  mutation UpdateTableColumns($input: UpdateDataTableColumnsInput!) {
    updateDataTableColumns(input: $input) {
      ...DataTableFragment
    }
  }

  ${DataTableFragment}
`;

export const DeleteTable = gql`
  mutation DeleteTable($ID: CommonID!, $source: String!) {
    deleteDataTable(ID: $ID, source: $source)
  }
`;

export const SyncTableColumns = gql`
  mutation SyncTableColumns($ID: CommonID!) {
    refreshDataTableColumns(ID: $ID) {
      ...DataTableFragment
    }
  }

  ${DataTableFragment}
`;

export const CreateNumbrzTable = gql`
  mutation CreateNumbrzTable($input: CreateNumbrzTableInput!) {
    createNumbrzTable(input: $input) {
      ...DataTableFragment
    }
  }
  ${DataTableFragment}
`;

export const AddTableToNumbrzDatabase = gql`
  mutation addTableToNumbrzDatabase($name: String, $tablesetID: CommonID!) {
    addTableToNumbrzDatabase(name: $name, tablesetID: $tablesetID) {
      ...DataTableFragment
    }
  }
  ${DataTableFragment}
`;

export const CreateNumbrzDatabase = gql`
  mutation CreateNumbrzDatabase($name: String) {
    createNumbrzDatabase(name: $name) {
      ...TablesetFragment
    }
  }
  ${TablesetFragment}
`;

export const TruncateDataTable = gql`
  mutation truncateDataTable($ID: CommonID!) {
    truncateDataTable(ID: $ID)
  }
`;

export const SetDeployKeys = gql`
  mutation SetDeployKeys(
    $tables: [SetDeployKeyInput!]
    $tablesets: [SetDeployKeyInput!]
  ) {
    setDeployKeys(tables: $tables, tablesets: $tablesets)
  }
`;
