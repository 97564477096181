import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import * as colors from '<components>/colors';

import Button from '<components>/Button';
import { useMutation } from '<src>/apollo/client';

import Dialog from '<components>/Dialog';
import TextInput from '<components>/TextInput';

import {
  GetCredentials,
  CreateCredential,
  ReauthorizeCredential,
} from '<sections>/account/queries';

import GoogleSpreadsheetPane from './GoogleSpreadsheetPane';
import DropboxPane from './DropboxPane';
import SnowflakeDatabasePane from './SnowflakeDatabasePane';
import ExcelSpreadsheetPane from './ExcelSpreadsheetPane';
import QuickbooksPane from './QuickbooksPane';
import MotherDuckPane from './MotherDuckPane';

const DialogFooter = styled(Dialog.Footer)`
  & > button {
    margin-left: 0;
    margin-right: 10px;
  }
  & > button:last-child {
    margin-right: 0;
  }
  display: flex;
`;

const StyledDialog = styled(Dialog)`
  min-width: 450px;
`;

const StyledDialogBody = styled(Dialog.Body)`
  padding-top: 10px;
`;

export const StyledTextInput = styled(TextInput)`
  width: 100%;
  padding: 7px 10px;
  border: 1px solid ${colors.gray_border};
`;

function getBtnLbl(connecting, cred) {
  if (connecting) {
    return cred ? 'Authorizing' : 'Connecting';
  } else {
    return cred ? 'Reauthorize' : 'Connect';
  }
}

export default function IntegrationDialog({
  visible,
  onDialogClose,
  connectorName = 'google',
  credential,
  setActiveCred,
}) {
  /*
    This dialog will allow users to create a new credential
    or reauthorize existing one (if credential attr is passed in)
  */

  const [reauthorize] = useMutation(ReauthorizeCredential, {
    update: (cache, { data: { reauthorizeCredential } }) => {
      const { listCredentials = [] } = cache.readQuery({
        query: GetCredentials,
      });
      const credIndex = listCredentials.findIndex(
        (c) => c.ID === reauthorizeCredential.ID
      );
      if (credIndex >= 0) {
        const newCreds = [...listCredentials];
        newCreds[credIndex] = reauthorizeCredential;

        cache.writeQuery({
          query: GetCredentials,
          data: {
            listCredentials: newCreds,
          },
        });
      }

      setActiveCred(undefined);
      onDialogClose();
    },
  });
  const title = credential ? 'Reauthorize' : 'Authenticate new';

  const [createCredential] = useMutation(CreateCredential, {
    update: (cache, { data: { createCredential } }) => {
      const { listCredentials } = cache.readQuery({
        query: GetCredentials,
      });
      const { ...credToAdd } = createCredential;

      cache.writeQuery({
        query: GetCredentials,
        data: {
          listCredentials: listCredentials.concat([credToAdd]),
        },
      });

      onDialogClose();
    },
  });

  const callback = credential ? reauthorize : createCredential;

  let CreateComp;

  switch (credential ? credential.providerName : connectorName) {
    case 'google':
    case 'gsheets':
    case 'bigquery':
      CreateComp = GoogleSpreadsheetPane;
      break;
    case 'snowflake':
      CreateComp = SnowflakeDatabasePane;
      break;
    case 'microsoft':
    case 'msexcel':
      CreateComp = ExcelSpreadsheetPane;
      break;
    case 'dropbox':
    case 'files':
      CreateComp = DropboxPane;
      break;
    case 'quickbooks':
      CreateComp = QuickbooksPane;
      break;
    case 'motherduck':
    case 'motherDuck':
      CreateComp = MotherDuckPane;
      break;
    default:
      throw new TypeError(`Invalid tab: ${connectorName}`);
  }

  return (
    <StyledDialog visible={visible ? 1 : 0} onRequestClose={onDialogClose}>
      <Dialog.Header>
        <Dialog.Headline>{`${title} user`}</Dialog.Headline>
        <Button.DialogClose onClick={onDialogClose} />
      </Dialog.Header>

      <CreateComp
        createCredential={callback}
        credential={credential}
        connectorName={connectorName}
      >
        {(body, doConnect, connecting, btnDisabled) => {
          return (
            <Fragment>
              <StyledDialogBody>{body}</StyledDialogBody>
              {connectorName !== 'quickbooks' && (
                <DialogFooter>
                  <Button
                    onClick={doConnect}
                    disabled={connecting || !doConnect || btnDisabled}
                  >
                    {getBtnLbl(connecting, credential)}
                  </Button>
                </DialogFooter>
              )}
            </Fragment>
          );
        }}
      </CreateComp>
    </StyledDialog>
  );
}
