import React from 'react';
import fp from 'lodash/fp';

import DataTablePane from '<components>/DataTablePane';

export default function TablePane({ table = {}, history, tablesetState }) {
  const { tableAPI, requiresDateInput, extTableAPI } = tablesetState;
  const {
    updateTable,
    onDelete,
    onUpdate,
    onUpdateLinkParams,
    onUpdateColumn,
    onUpdateColumns,
    onAddColumn,
    onDeleteColumn,
    onSyncColumns,
    onTruncate,
  } = tableAPI;
  const source = fp.getOr('', 'source.__typename', table);
  const { isValidNew } = extTableAPI;

  return (
    <DataTablePane
      table={table}
      history={history}
      onUpdate={(field, value) => onUpdate(field, value, table)}
      onUpdateLinkParams={(tableID, params, newTableName) =>
        onUpdateLinkParams(tableID, params, newTableName)
      }
      onDelete={onDelete}
      onAddColumn={(label, type) => onAddColumn(label, type, table.ID, source)}
      onDeleteColumn={(columnID) => onDeleteColumn(columnID, table.ID, source)}
      onUpdateColumn={(columnID, field, value) =>
        onUpdateColumn(columnID, field, value, table)
      }
      onUpdateColumns={(columns) => onUpdateColumns(columns, table.ID, source)}
      onSyncColumns={() => onSyncColumns(table.ID)}
      onTruncateTable={() => onTruncate(table.ID)}
      hideHeader
      requiresDateInput={requiresDateInput}
      isValidExt={isValidNew}
      updateTable={updateTable}
    />
  );
}
