import React from 'react';

import styled from '@emotion/styled';
import { Message } from 'semantic-ui-react';
import * as colors from '<src>/components/colors';

const StyledWarning = styled(Message, {
  shouldForwardProp: (prop) => !['maxWidth', 'margin'].includes(prop),
})`
  max-width: ${(props) => props.maxWidth && props.maxWidth};
  width: ${(props) => props.width && props.width};
  margin: ${(props) =>
    props.margin ? `${props.margin} !important` : '0 0 10px 0 !important'};
  color: ${colors.red1c} !important;
  border: 1px solid ${colors.red1c} !important;
  min-height: unset !important;
  box-shadow: none !important;
  div.header {
    color: ${colors.red1c} !important;
    font-size: 13px !important;
  }

  font-size: 12px !important;

  ul {
    margin: 5px 0 0 0;
    padding-left: 0;
    list-style-type: none;
  }
`;

export default function ErrorMessages({
  message,
  issues = [],
  noShadow,
  margin,
  maxWidth,
  width,
}) {
  if (issues.length === 0) {
    return null;
  }

  return (
    issues &&
    issues.length > 0 && (
      <StyledWarning
        error
        margin={margin}
        maxWidth={maxWidth}
        width={width}
        noshadow={noShadow ? noShadow.toString() : 'false'}
      >
        {message && <Message.Header>{message}</Message.Header>}
        <ul>
          {issues.map((issue, idx) => (
            <li key={idx}>{issue.message}</li>
          ))}
        </ul>
      </StyledWarning>
    )
  );
}
