import React, { useEffect, useMemo, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { useHistory, useParams } from 'react-router-dom';

import { useQuery, useLazyQuery, useMutation } from '<src>/apollo/client';

import Masthead from '<components>/Masthead';
import * as colors from '<components>/colors';
import MainLayout from '<components>/MainLayout';
import WaveSpinner from '<components>/WaveSpinner';
import {
  ColumnWrapper,
  FlexColumnWrapper,
  PageContainer,
} from '<components>/NumbrzPageComponents';
import ResizeableSplitView from '<sections>/flows/flow-testing/ResizeableSplitView';
import Sidebar from '<components>/MainLayout/Sidebar';
import { FlowContainer } from '<components>/NumbrzVerticalEditor';

import DeployDialog from '<sections>/projects/components/DeployDialog';
import LibraryDialog from '<sections>/catalog/components/LibraryDialog';
import { ContentWrapper } from '<sections>/flows/styles';
import { DeployTemplate, UpgradeProject } from '<sections>/projects/queries';

import { GetCatalogProject, GetCatalogSnapshot } from '../../queries';
import CatalogProject from './CatalogProject';

export default function CatalogProjectPage() {
  const history = useHistory();
  const { slugOrID } = useParams();
  const [deployVisible, setDeployVisible] = useState(false);
  const [libraryDialogVisible, setLibraryDialogVisible] = useState(false);
  const [activeFx, setActiveFx] = useState();
  const [activeFlow, setActiveFlow] = useState();

  const [deploying, setDeploying] = useState(false);

  const { data = {}, loading } = useQuery(GetCatalogProject, {
    variables: {
      slugOrID,
    },
  });
  const { catalogProject } = data;

  const [
    getCatalogSnapshot,
    { data: snapshotData, loading: loadingSnapshot, called },
  ] = useLazyQuery(GetCatalogSnapshot);

  const libFunctions = useMemo(
    () => snapshotData?.catalogSnapshot?.functions || [],
    [snapshotData]
  );

  const libFlows = useMemo(
    () => snapshotData?.catalogSnapshot?.flows || [],
    [snapshotData]
  );

  const [deployTemplate] = useMutation(DeployTemplate);
  const [upgradeProject] = useMutation(UpgradeProject);

  useEffect(() => {
    if (!loading && data?.catalogProject) {
      window.Intercom('trackEvent', 'catalog_model_viewed', {
        modelID: data.catalogProject.ID,
      });
    }
    // Fetch library project snapshot data
    if (!called && catalogProject?.isLibrary) {
      getCatalogSnapshot({
        variables: { projectID: catalogProject?.ID },
      });
    }
  }, [data, loading, called, catalogProject, snapshotData, getCatalogSnapshot]);

  if (loading) return <WaveSpinner />;

  const doLibraryImport = async () => {
    setLibraryDialogVisible(true);
  };

  const doDeploy = async () => {
    if (catalogProject.type === 'DeployService') {
      setDeployVisible(true);
    } else {
      window.Intercom('trackEvent', 'project_deploy_start', {
        sourceID: catalogProject.ID,
        type: 'Template',
      });
      setDeploying(true);
      const { data, error } = await deployTemplate({
        variables: {
          input: {
            projectID: catalogProject.ID,
          },
        },
      });
      if (error) {
        throw error;
      }
      window.Intercom('trackEvent', 'project_deploy_success', {
        sourceID: catalogProject.ID,
        type: 'Template',
      });
      history.push(`/models/${data.deployTemplate.ID}`);
    }
  };

  const doUpgrade = async (projectID) => {
    setDeploying(true);
    await upgradeProject({
      variables: { ID: projectID },
      update: (cache, { data: { upgradeProject: newProject } }) => {
        if (!newProject) {
          return;
        }

        const { catalogProject: cachedCtlg } = cache.readQuery({
          query: GetCatalogProject,
          variables: { slugOrID },
        });
        cache.writeQuery({
          query: GetCatalogProject,
          variables: { slugOrID },
          data: {
            catalogProject: {
              ...cachedCtlg,
              deployments: cachedCtlg.deployments.map((depl) =>
                depl.ID !== projectID
                  ? depl
                  : {
                      ...depl,
                      upgradeAvailable: null,
                    }
              ),
            },
          },
        });
      },
    });
    setDeploying(false);
  };

  const breadcrumbs = [
    {
      title: 'Catalog',
      path: '/catalog',
      type: 'root',
    },
    {
      title: catalogProject.name || 'Untitled Model',
      path: `/catalog/${catalogProject.ID}`,
    },
  ];

  return (
    <DocumentTitle title="Catalog - Numbrz">
      <MainLayout
        navigation={false}
        overflowY="hidden"
        header={<Masthead breadcrumbs={breadcrumbs} addShadow />}
        main={
          <PageContainer>
            <ResizeableSplitView
              left={
                <FlexColumnWrapper width="100%">
                  <ContentWrapper>
                    <Sidebar />

                    <FlowContainer>
                      <ColumnWrapper>
                        <CatalogProject
                          catalogProject={catalogProject}
                          onDeploy={
                            catalogProject.isLibrary
                              ? doLibraryImport
                              : doDeploy
                          }
                          onUpgrade={doUpgrade}
                          deploying={deploying || deployVisible}
                          libFunctions={libFunctions}
                          libFlows={libFlows}
                          loading={loading || loadingSnapshot}
                          onViewFx={(fxID) => {
                            setActiveFlow(null);
                            setActiveFx(fxID);
                            setLibraryDialogVisible(true);
                          }}
                          onViewFlow={(ID) => {
                            setActiveFx(null);
                            setActiveFlow(ID);
                            setLibraryDialogVisible(true);
                          }}
                        />
                        <DeployDialog
                          visible={deployVisible}
                          projectID={slugOrID}
                          onRequestClose={() => setDeployVisible(false)}
                          skipAccounts
                        />
                        {libraryDialogVisible && (
                          <LibraryDialog
                            libraryName={catalogProject.name}
                            visible={libraryDialogVisible}
                            onClose={() => {
                              setActiveFx(null);
                              setActiveFlow(null);
                              setLibraryDialogVisible(false);
                            }}
                            catalogProjectID={catalogProject?.ID}
                            libFunctions={libFunctions}
                            libFlows={libFlows}
                            loading={loadingSnapshot}
                            activeFxID={activeFx}
                            activeFlowID={activeFlow}
                          />
                        )}
                      </ColumnWrapper>
                    </FlowContainer>
                  </ContentWrapper>
                </FlexColumnWrapper>
              }
            />
          </PageContainer>
        }
        bgColor={colors.white}
      />
    </DocumentTitle>
  );
}
